@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


h1, h2, h3, h4, h5, h6, p, ul, a {
  font-family: 'Merriweather', serif;
  color: #644949;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 26px;
}

ul, p, a {
  font-size: 16px;
}

@media screen and (min-width:768px) {
  h1 {
    font-size: 56px;  
  }
  h2 {
    font-size: 48px;
  }
  h3 {
    font-size: 32px;
  }
  ul, p, a {
    font-size: 24px;
  }
}

img {
  margin: 0;
  padding: 0;
  max-width: 100%;
  height: auto;
  margin-bottom: -5px;
}

.images {
  width: 100%;
}

.content {
  text-align: center;
  margin: 1rem;
  padding: 1rem;
  border:1px solid #644949;
}
.content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.content ul li a {
  padding: 0.5rem;
  display: block;
}
@media screen and (min-width:768px) {
  .App {
    flex-direction: row;
  }
  .images {
    width: 50%;
  }  
  .content {
    margin: 2vw;
    padding: 2vw;
    width: 50%;
  }
}

.iframe_wrapper {
  position: relative;
}
.iframe_wrapper img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 128px;
  z-index: -4;
}

iframe {
  height:calc(90vh);
  width:calc(100vw);
  box-sizing: border-box;
}

.hyttebok_navigation {
  text-align: center;
  padding: 0.4rem;
}
.hyttebok_navigation a {
  display: inline-block;
  padding: 0 1rem;
  font-size: 14px;
}
@media screen and (min-width:768px) {
  .hyttebok_navigation a {
    font-size: 16px;
    padding: 0 2rem;
  }
}
